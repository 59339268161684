.section-files-senactripulante {
  width: 100%;
  min-height: 100vh;
  background-color: #072331;
  display: flex;
  justify-content: center;
  align-items: center;
}

.items-file-senactripulante {
  background-color: #072331;
  min-height: 180px;
  box-shadow: 0 0 10px rgb(59, 59, 59);
  border: 2px solid white;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.btn-download-senactripulante {
  text-decoration: none;
  color: white;
  background-color: #03bdf0;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}

.btn-download-senactripulante:hover {
  background-color: white;
  color: #072331;
}
