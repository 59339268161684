.section-kenvue {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 20px 0;
}

.div-list-doc {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.div-doc {
  width: 400px;
  background-color: #ff6b6b;
  border-radius: 8px;
  margin: 10px 0;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: -7px 8px 26px -13px rgba(0,0,0,0.75);
  -moz-box-shadow: -7px 8px 26px -13px rgba(0,0,0,0.75);
  box-shadow: -7px 8px 26px -13px rgba(0,0,0,0.75);
}

.a-title-item {
  font-size: 20px;
  text-decoration: none;
  color: white;
}

.button-doc {
  margin: 5px 0;
  color: black;
  text-decoration: none;
  padding: 10px 0;
  background-color: #ffb000;
  color: white;
}

.button-doc-disable {
  opacity: 0.4;
}

@media(max-width: 600px){
  .div-doc {
    width: 90%;
  }
}

.content-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}
